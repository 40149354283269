import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <Link to="/about">About</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <div className="footer-copyright">
          © {new Date().getFullYear()} <a href="https://codehummus.com" target="_blank" rel="noreferrer">CodeHummus</a>. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;