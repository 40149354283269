import React, { useState, useEffect, useRef, useCallback } from 'react';
import { db } from '../firebase';
import { doc, setDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import Flashcard from './Flashcard';
import './Deck.css';

const getRandomCards = (allCards) => {
  return [...allCards].sort(() => 0.5 - Math.random());
};

const Deck = ({
  isOrderModalOpen,
  setIsOrderModalOpen,
  userId,
  currentSet,
  charactersUpdated,
  onManageCharacters
}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentSideIndex, setCurrentSideIndex] = useState(0);
  const [sideOrder, setSideOrder] = useState(['hanzi', 'pinyin', 'translation']);
  const [tempSideOrder, setTempSideOrder] = useState(sideOrder);
  const [options, setOptions] = useState([]);
  const [points, setPoints] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showCardFeedback, setShowCardFeedback] = useState(false);
  const [currentCardAnswers, setCurrentCardAnswers] = useState({ side2: null, side3: null });
  const [currentTestCards, setCurrentTestCards] = useState([]);
  const [allCharacters, setAllCharacters] = useState([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [showIncorrectAnswers, setShowIncorrectAnswers] = useState(false);
  const [isPracticeMode, setIsPracticeMode] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const loadUserSettings = async () => {
      try {
        const docRef = doc(db, 'userSettings', userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists() && docSnap.data().sideOrder) {
          setSideOrder(docSnap.data().sideOrder);
          setTempSideOrder(docSnap.data().sideOrder);
        }
      } catch (error) {
        console.error('Error loading user settings:', error);
      }
    };

    if (userId) {
      loadUserSettings();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchPromises = currentSet.map(set => {
          // If it's a demo set, don't filter by userId
          if (set.isDemo) {
            return getDocs(
              query(
                collection(db, 'characters'),
                where('setId', '==', set.id)
              )
            );
          }
          // For regular sets, include userId filter
          return getDocs(
            query(
              collection(db, 'characters'),
              where('userId', '==', userId),
              where('setId', '==', set.id)
            )
          );
        });

        const snapshots = await Promise.all(fetchPromises);
        const chars = snapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        );

        setAllCharacters(chars);
        setCurrentTestCards(getRandomCards(chars));
        setCurrentCardIndex(0);
        setCurrentSideIndex(0);
        setPoints(0);
        setShowResult(false);
        setShowCardFeedback(false);
        setCurrentCardAnswers({ side2: null, side3: null });
        setIncorrectAnswers([]);
        setShowIncorrectAnswers(false);
        setIsPracticeMode(false);
      } catch (error) {
        console.error('Error fetching characters:', error);
      }
    };

    if (currentSet?.length > 0) {
      fetchData();
    }
  }, [userId, currentSet, charactersUpdated]);

  const closeModal = useCallback(() => {
    setIsOrderModalOpen(false);
    setTempSideOrder(sideOrder);
  }, [setIsOrderModalOpen, sideOrder]);

  const getRandomOptions = useCallback((correctAnswer, type) => {
    const options = [correctAnswer];
    const availableAnswers = allCharacters
      .filter(card => card[type] !== correctAnswer)
      .map(card => card[type]);

    while (options.length < 3 && availableAnswers.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableAnswers.length);
      const option = availableAnswers[randomIndex];
      if (!options.includes(option)) {
        options.push(option);
      }
    }

    return options.sort(() => Math.random() - 0.5);
  }, [allCharacters]);

  useEffect(() => {
    if (currentSideIndex > 0) {
      const currentCard = currentTestCards[currentCardIndex];
      const currentSide = sideOrder[currentSideIndex];
      setOptions(getRandomOptions(currentCard[currentSide], currentSide));
    }
  }, [currentSideIndex, currentCardIndex, sideOrder, currentTestCards, getRandomOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (isOrderModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOrderModalOpen, closeModal]);

  if (allCharacters.length === 0) {
    return (
      <div className="empty-state">
        <h2>No Characters Added</h2>
        <p>Add some characters to start practicing</p>
        <button className="btn btn-primary" onClick={onManageCharacters}>
          Manage Characters
        </button>
      </div>
    );
  }

  const handleAnswer = (selectedAnswer) => {
    const currentCard = currentTestCards[currentCardIndex];
    const currentSide = sideOrder[currentSideIndex];
    const correct = selectedAnswer === currentCard[currentSide];

    if (!correct) {
      setIncorrectAnswers(prev => [...prev, {
        card: currentCard,
        side: currentSideIndex === 1 ? 'pinyin' : 'translation',
        userAnswer: selectedAnswer,
        correctAnswer: currentCard[currentSide]
      }]);
    }

    if (currentSideIndex === 1) {
      setCurrentCardAnswers(prev => ({ ...prev, side2: correct }));
      setCurrentSideIndex(prev => prev + 1);
    } else {
      setCurrentCardAnswers(prev => ({ ...prev, side3: correct }));
      setShowCardFeedback(true);
    }

    if (correct) {
      setPoints(prev => prev + 0.5);
    }
  };

  const handleNext = () => {
    setShowCardFeedback(false);
    setCurrentCardAnswers({ side2: null, side3: null });

    if (currentCardIndex === currentTestCards.length - 1) {
      setShowResult(true);
    } else {
      setCurrentCardIndex(prev => prev + 1);
      setCurrentSideIndex(0);
    }
  };

  const resetTest = (cards = allCharacters) => {
    setCurrentTestCards(getRandomCards(cards));
    setCurrentCardIndex(0);
    setCurrentSideIndex(0);
    setPoints(0);
    setShowResult(false);
    setShowCardFeedback(false);
    setCurrentCardAnswers({ side2: null, side3: null });
    setIncorrectAnswers([]);
    setShowIncorrectAnswers(false);
    setIsPracticeMode(false);
  };

  const startPracticeIncorrect = () => {
    const uniqueIncorrectCards = Array.from(
      new Set(incorrectAnswers.map(item => item.card.id))
    ).map(id =>
      incorrectAnswers.find(item => item.card.id === id).card
    );

    if (uniqueIncorrectCards.length > 0) {
      setIsPracticeMode(true);
      resetTest(uniqueIncorrectCards);
    }
  };

  const moveItem = (fromIndex, toIndex) => {
    const newOrder = [...tempSideOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(toIndex, 0, movedItem);
    setTempSideOrder(newOrder);
  };

  const submitChanges = async () => {
    try {
      const docRef = doc(db, 'userSettings', userId);
      await setDoc(docRef, { sideOrder: tempSideOrder }, { merge: true });
      setSideOrder(tempSideOrder);
      setIsOrderModalOpen(false);
      resetTest();
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  if (showResult) {
    return (
      <div className="result-container">
        <h2>Test Complete!</h2>
        <p>The result: {points} points out of {currentTestCards.length}</p>
        {incorrectAnswers.length > 0 && (
          <div className="buttons-container">
            {showIncorrectAnswers ? (
              <div className="incorrect-answers">
                {incorrectAnswers.map((item, index) => (
                  <div key={index} className="incorrect-item">
                    <div className="card-info">
                      {item.card.hanzi} / {item.card.pinyin} / {item.card.translation}
                    </div>
                    <p>{item.side}: {item.userAnswer} (correct: {item.correctAnswer})</p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowIncorrectAnswers(true)}
                >
                  Show Incorrect Answers ({incorrectAnswers.length})
                </button>
                <button
                  className="btn btn-primary"
                  onClick={startPracticeIncorrect}
                >
                  Practice Incorrect Cards
                </button>
              </>
            )}
          </div>
        )}
        <button
          className="btn btn-primary"
          onClick={() => resetTest()}
          style={{ marginTop: '1rem' }}
        >
          Start New Test{isPracticeMode ? ' (All Cards)' : ''}
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="deck">
        <div className="progress-counter">
          Card {currentCardIndex + 1}/{currentTestCards.length}
          {isPracticeMode && (
            <span className="practice-mode-indicator"> (Practice Mode)</span>
          )}
        </div>

        {currentSideIndex === 0 ? (
          <>
            <h3 className="card-label">{sideOrder[currentSideIndex].charAt(0).toUpperCase() + sideOrder[currentSideIndex].slice(1)}</h3>
            <Flashcard
              data={currentTestCards[currentCardIndex]}
              currentSide={sideOrder[currentSideIndex]}
              onFlip={() => setCurrentSideIndex(prev => prev + 1)}
            />
            <button className="btn btn-secondary restart-btn" onClick={() => resetTest()}>
              Restart Test
            </button>
          </>
        ) : showCardFeedback ? (
          <div className="card-feedback">
            <h3>Card Summary</h3>
            <div className="card-info">
              {currentTestCards[currentCardIndex].hanzi} / {currentTestCards[currentCardIndex].pinyin} / {currentTestCards[currentCardIndex].translation}
            </div>
            <div className="feedback-section top">
              <p>Pinyin: {currentCardAnswers.side2 ? "0.5 points" : "0 points"}</p>
              {currentCardAnswers.side2 ? null : (
                <p className="correct-answer">
                  Correct answer: {currentTestCards[currentCardIndex][sideOrder[1]]}
                </p>
              )}
            </div>
            <div className="feedback-section bottom">
              <p>Translation: {currentCardAnswers.side3 ? "0.5 points" : "0 points"}</p>
              {currentCardAnswers.side3 ? null : (
                <p className="correct-answer">
                  Correct answer: {currentTestCards[currentCardIndex][sideOrder[2]]}
                </p>
              )}
            </div>
            <button className="btn btn-primary" onClick={handleNext}>
              {currentCardIndex === currentTestCards.length - 1 ? "Show Results" : "Next Card"}
            </button>
          </div>
        ) : (
          <div className="multiple-choice">
            <h3>Choose the correct {sideOrder[currentSideIndex]}:</h3>
            <div className="options-container">
              {options.map((option, index) => (
                <button
                  key={index}
                  className="option-btn"
                  onClick={() => handleAnswer(option)}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {isOrderModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <button className="close-icon" onClick={closeModal}>&#10005;</button>
            <h2>Change Flashcard Order</h2>
            <ul className="order-list">
              {tempSideOrder.map((item, index) => (
                <li key={item}>
                  <span className="side-label">
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </span>
                  <div>
                    {index > 0 && (
                      <button onClick={() => moveItem(index, index - 1)}>↑</button>
                    )}
                    {index < tempSideOrder.length - 1 && (
                      <button onClick={() => moveItem(index, index + 1)}>↓</button>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <button className="btn btn-primary submit-btn" onClick={submitChanges}>
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Deck;