import React, { useState, useEffect } from 'react';
import { BrowserRouter, Link, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Auth from './components/Auth';
import Deck from './components/Deck';
import CharacterManager from './components/CharacterManager';
import SetManager from './components/SetManager';
import './App.css';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Footer from './components/Footer';

const AppContent = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [showSetManager, setShowSetManager] = useState(false);
  const [currentSet, setCurrentSet] = useState(null);
  const [charactersUpdated, setCharactersUpdated] = useState(0);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const toggleOrderModal = () => {
    setIsOrderModalOpen(!isOrderModalOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentSet(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleSetSelect = (sets) => {
    setCurrentSet(sets);
    setShowSetManager(false);
    setCharactersUpdated(prev => prev + 1);
  };

  return (
    <div className="App">
      <div className="top-bar">
        <h1 className="title">
          <Link to="/">Hanzi Flashcards</Link>
        </h1>
        {user && (
          <div className="settings-menu">
            <button
              className="btn manage-sets-btn"
              onClick={() => {
                navigate('/');
                setShowSetManager(true);
              }}
            >
              Select Set
            </button>
            {currentSet && currentSet.length > 0 && (
              <>
                <button className="btn swap-button" onClick={toggleOrderModal}>
                  <span>Change Order</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="1 4 1 10 7 10"></polyline>
                    <polyline points="23 20 23 14 17 14"></polyline>
                    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                  </svg>
                </button>
              </>
            )}
            <button className="logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
      <main>
        <Routes>
          {/* Public routes */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />

          {/* Protected routes */}
          <Route path="/" element={
            user ? (
              <>
                {currentSet ? (
                  <Deck
                    isOrderModalOpen={isOrderModalOpen}
                    setIsOrderModalOpen={setIsOrderModalOpen}
                    userId={user.uid}
                    currentSet={currentSet}
                    charactersUpdated={charactersUpdated}
                  />
                ) : (
                  <div className="empty-state">
                    <h2>Select a Set</h2>
                    <p>Please select or create a character set to start practicing</p>
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowSetManager(true)}
                    >
                      Select Set
                    </button>
                  </div>
                )}
                {showSetManager && (
                  <div className="modal-overlay">
                    <SetManager
                      userId={user.uid}
                      onClose={() => setShowSetManager(false)}
                      onSelectSet={handleSetSelect}
                      onManageCharacters={(set) => navigate(`/manage-set/${set.id}`)}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="auth-container">
                <Auth />
              </div>
            )
          } />
          <Route path="/manage-set/:setId" element={
            user ? (
              <CharacterManager userId={user.uid} />
            ) : (
              <Navigate to="/" replace />
            )
          } />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;