import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import Papa from 'papaparse';

const CSVUploader = ({ userId, setId, onUploadComplete }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    setError(null);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        try {
          const characters = results.data.map(row => ({
            hanzi: row['Character'],
            pinyin: row['Pinyin'],
            translation: row['Translation'],
            userId,
            setId
          }));

          const addPromises = characters.map(char =>
            addDoc(collection(db, 'characters'), char)
          );

          await Promise.all(addPromises);
          onUploadComplete();
        } catch (err) {
          setError('Error uploading characters');
        } finally {
          setUploading(false);
        }
      },
      error: (err) => {
        setError('Error parsing CSV file');
        setUploading(false);
      }
    });
  };

  return (
    <div className="csv-uploader">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        disabled={uploading}
      />
      {uploading && <div>Uploading...</div>}
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default CSVUploader;