import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAju2ow-U6nwp2efFKmrmDZHo7HpYi1OzE",
    authDomain: "hanzi-flashcards.firebaseapp.com",
    projectId: "hanzi-flashcards",
    storageBucket: "hanzi-flashcards.firebasestorage.app",
    messagingSenderId: "632841745841",
    appId: "1:632841745841:web:c5e4f542991d94a612f990",
    measurementId: "G-RBBV01HZ6J"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);