import React from 'react';
import './Privacy.css';

const Privacy = () => {
  return (
    <div className="page-container privacy-policy">
      <h1>Privacy Policy</h1>
      <p className="last-updated">Last updated: December 13, 2024</p>

      <section>
        <h2>Introduction</h2>
        <p>Welcome to Triple-Sided Flashcards ("we," "our," or "us"). We are committed to protecting your privacy and ensuring the security of any personal information you provide while using our application.</p>
      </section>

      <section>
        <h2>Service Tiers</h2>
        <p>Our application offers two subscription tiers:</p>
        <ul>
          <li>Free Tier: Limited to 20 flashcards</li>
          <li>Annual Tier: Unlimited flashcards for $19.99 per year</li>
        </ul>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <h3>User-Provided Information</h3>
        <ul>
          <li>Flashcard content you create</li>
          <li>CSV files you upload containing character sets</li>
          <li>Application preferences and settings</li>
          <li>Payment information (for Annual tier subscribers)</li>
        </ul>

        <h3>Automatically Collected Information</h3>
        <ul>
          <li>Device information (operating system, device type)</li>
          <li>Application usage statistics</li>
          <li>Error logs for troubleshooting</li>
          <li>Authentication data</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>To provide and maintain the flashcard learning functionality</li>
          <li>To manage your subscription and access to features</li>
          <li>To save and restore your learning progress</li>
          <li>To improve and optimize application performance</li>
          <li>To troubleshoot technical issues</li>
          <li>To implement user preferences and settings</li>
        </ul>
      </section>

      <section>
        <h2>Data Storage and Security</h2>
        <h3>Firebase Storage</h3>
        <p>All user data is stored in Google Firebase, including:</p>
        <ul>
          <li>User authentication information</li>
          <li>Flashcard sets and learning progress</li>
          <li>Subscription status</li>
          <li>Application preferences and settings</li>
        </ul>

        <h3>Security Measures</h3>
        <p>We implement several security measures to protect your data:</p>
        <ul>
          <li>All data transmission between your device and Firebase is encrypted</li>
          <li>We use secure authentication mechanisms</li>
          <li>Regular security monitoring and updates</li>
          <li>Protected database access</li>
        </ul>
      </section>

      <section>
        <h2>Data Sharing</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to third parties. However, we use the following service providers:</p>
        <ul>
          <li>Google Firebase for data storage and authentication</li>
          <li>Payment processor for subscription management</li>
        </ul>
        <p>These service providers are bound by confidentiality agreements and can only use your data for providing the specified services.</p>
      </section>

      <section>
        <h2>Children's Privacy</h2>
        <p>While we don't have age restrictions, if you are under 13 years of age, please use this application with parent or guardian supervision. We do not knowingly collect personal information from children under 13.</p>
      </section>

      <section>
        <h2>Changes to This Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access your stored data</li>
          <li>Request corrections to your data</li>
          <li>Delete your account and associated data</li>
          <li>Export your learning progress and content</li>
          <li>Cancel your subscription at any time</li>
        </ul>
      </section>

      <section>
        <h2>Data Deletion</h2>
        <p>To remove your data:</p>
        <ol>
          <li>Contact us to request account deletion</li>
          <li>We will remove all your data from Firebase</li>
          <li>Cancel your subscription if applicable</li>
        </ol>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at: <a href="mailto:slava@codehummus.com" target="_blank" rel="noreferrer">slava@codehummus.com</a></p>
    </section>

      <section>
        <h2>Consent</h2>
        <p>By using our application, you consent to this Privacy Policy and agree to its terms.</p>
      </section>
    </div>
  );
};

export default Privacy;