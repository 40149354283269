import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import './SetManager.css';

const SetManager = ({ userId, onClose, onSelectSet, onManageCharacters }) => {
  const [sets, setSets] = useState([]);
  const [selectedSets, setSelectedSets] = useState([]);
  const [newSetName, setNewSetName] = useState('');
  const [error, setError] = useState('');

  const fetchSets = useCallback(async () => {
    try {
      // Fetch user's sets
      const userSetsQuery = query(collection(db, 'sets'), where('userId', '==', userId));
      const userSetsSnapshot = await getDocs(userSetsQuery);
      const userSets = userSetsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Fetch demo set
      const demoSetDoc = await getDoc(doc(db, 'sets', 'demo-set'));
      const allSets = demoSetDoc.exists()
        ? [{ id: 'demo-set', ...demoSetDoc.data() }, ...userSets]
        : userSets;

      setSets(allSets);
    } catch (error) {
      setError('Error fetching sets');
    }
  }, [userId]);

  useEffect(() => {
    fetchSets();
  }, [fetchSets]);

  const handleCreateSet = async (e) => {
    e.preventDefault();
    if (!newSetName.trim()) {
      setError('Set name is required');
      return;
    }

    try {
      await addDoc(collection(db, 'sets'), {
        userId,
        name: newSetName.trim(),
        createdAt: new Date()
      });
      setNewSetName('');
      setError('');
      fetchSets();
    } catch (error) {
      setError('Error creating set');
    }
  };

  const handleSelect = (set) => {
    if (selectedSets.find(s => s.id === set.id)) {
      setSelectedSets(prev => prev.filter(s => s.id !== set.id));
    } else {
      setSelectedSets(prev => [...prev, set]);
    }
  };

  return (
    <div className="set-manager">
      <h2>Select Sets</h2>
      <form onSubmit={handleCreateSet} className="create-set-form">
        <input
          type="text"
          value={newSetName}
          onChange={(e) => setNewSetName(e.target.value)}
          placeholder="New Set Name"
        />
        <button type="submit" className="btn btn-primary">Create Set</button>
      </form>

      {error && <p className="error">{error}</p>}

      <div className="sets-list">
        {sets.map(set => (
          <div
            key={set.id}
            className={`set-item ${selectedSets.find(s => s.id === set.id) ? 'selected' : ''}`}
          >
            <span className="set-name">
              {set.name}
            </span>
            <div className="set-actions">
              <button
                className={`btn ${selectedSets.find(s => s.id === set.id) ? 'btn-primary' : 'btn-secondary'}`}
                onClick={() => handleSelect(set)}
              >
                {selectedSets.find(s => s.id === set.id) ? 'Selected' : 'Select'}
              </button>
              {!set.isDemo && (
                <button
                  className="btn btn-secondary"
                  onClick={() => onManageCharacters(set)}
                >
                  Manage
                </button>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="set-manager-actions">
        <button
          className="btn btn-primary"
          disabled={selectedSets.length === 0}
          onClick={() => onSelectSet(selectedSets)}
        >
          Start Practice ({selectedSets.length} sets)
        </button>
        <button onClick={onClose} className="btn btn-secondary">
          Close
        </button>
      </div>
    </div>
  );
};

export default SetManager;