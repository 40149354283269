import React from 'react';
import { Link } from 'react-router-dom';
import './Terms.css';

const Terms = () => {
  return (
    <div className="page-container terms">
      <h1>Terms and Conditions</h1>
      <p className="last-updated">Last updated: December 13, 2024</p>

      <section>
        <h2>1. Agreement to Terms</h2>
        <p>By accessing and using Triple-Sided Flashcards (the "Application"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of these Terms, you do not have permission to access or use the Application.</p>
      </section>

      <section>
        <h2>2. Service Description</h2>
        <p>Triple-Sided Flashcards is a web-based application accessible as a Progressive Web Application (PWA) that provides flashcard-based learning tools for Chinese characters, offering both Simplified and Traditional Chinese options, with potential functionality for Japanese Kanji characters.</p>
      </section>

      <section>
        <h2>3. Subscription Plans</h2>
        <h3>3.1 Free Tier</h3>
        <ul>
          <li>Access to up to 20 flashcards</li>
          <li>Basic application features</li>
          <li>No payment required</li>
        </ul>

        <h3>3.2 Annual Subscription</h3>
        <ul>
          <li>Unlimited flashcards</li>
          <li>Full access to all features</li>
          <li>Price: $19.99 per year</li>
          <li>Auto-renewal unless cancelled by user</li>
        </ul>
      </section>

      <section>
        <h2>4. Payment and Refund Policy</h2>
        <h3>4.1 Payments</h3>
        <ul>
          <li>All payments are processed securely through our payment processor</li>
          <li>Subscription fees are charged annually</li>
          <li>Prices are subject to change with notice</li>
        </ul>

        <h3>4.2 Refund Policy</h3>
        <ul>
          <li>No refunds are provided for Annual subscriptions as users can evaluate the application through the Free Tier</li>
          <li>Exception: Pro-rated refunds will be issued if the service is discontinued</li>
        </ul>
      </section>

      <section>
        <h2>5. User Content</h2>
        <h3>5.1 Content Creation and Ownership</h3>
        <ul>
          <li>Users retain ownership of any content they create within the Application</li>
          <li>Users may use their created content without restrictions</li>
        </ul>

        <h3>5.2 Prohibited Content</h3>
        <p>Users agree not to create or upload content that:</p>
        <ul>
          <li>Infringes on intellectual property rights</li>
          <li>Contains malicious code</li>
          <li>Violates any applicable laws</li>
          <li>Contains inappropriate or offensive material</li>
        </ul>
      </section>

      <section>
        <h2>6. Service Availability and Modifications</h2>
        <h3>6.1 Service Availability</h3>
        <ul>
          <li>We strive to maintain continuous service availability</li>
          <li>We do not guarantee uninterrupted access to the Application</li>
          <li>We may perform maintenance or updates that temporarily interrupt service</li>
        </ul>

        <h3>6.2 Modifications</h3>
        <p>We reserve the right to:</p>
        <ul>
          <li>Modify or discontinue features</li>
          <li>Update the Application</li>
          <li>Change subscription terms with notice</li>
          <li>Discontinue the service with advance notice</li>
        </ul>
      </section>

      <section>
        <h2>7. Account Termination</h2>
        <p>We reserve the right to terminate or suspend accounts for:</p>
        <ul>
          <li>Violation of these Terms</li>
          <li>Fraudulent activity</li>
          <li>Abusive behavior towards other users or staff</li>
          <li>Non-payment of subscription fees</li>
          <li>Any other reason we deem appropriate</li>
        </ul>
      </section>

      <section>
        <h2>8. Limitation of Liability</h2>
        <h3>8.1 Service "As Is"</h3>
        <p>The Application is provided "as is" without warranties of any kind, either express or implied.</p>

        <h3>8.2 Liability Limitations</h3>
        <p>We shall not be liable for:</p>
        <ul>
          <li>Data loss</li>
          <li>Service interruptions</li>
          <li>Indirect, consequential, or incidental damages</li>
          <li>Issues arising from user hardware or software</li>
        </ul>
      </section>

      <section>
        <h2>9. Intellectual Property</h2>
        <h3>9.1 Application Rights</h3>
        <p>All rights, title, and interest in and to the Application are and will remain the exclusive property of Triple-Sided Flashcards.</p>

        <h3>9.2 User License</h3>
        <p>Users are granted a limited, non-exclusive, non-transferable license to use the Application in accordance with these Terms.</p>
      </section>

      <section>
        <h2>10. Privacy Policy</h2>
        <p>Our Privacy Policy, available at <Link to="/privacy">Privacy Policy</Link>, is incorporated into these Terms by reference.</p>
      </section>

      <section>
        <h2>11. Governing Law</h2>
        <p>These Terms shall be governed by and construed in accordance with the laws of Armenia, without regard to its conflict of law provisions.</p>
      </section>

      <section>
        <h2>12. Dispute Resolution</h2>
        <p>Any disputes arising from these Terms or the use of the Application shall be subject to the exclusive jurisdiction of the courts of Armenia.</p>
      </section>

      <section>
        <h2>13. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. We will notify users of any material changes via:</p>
        <ul>
          <li>Email notification</li>
          <li>Notice within the Application</li>
          <li>Updated "Last Modified" date on this document</li>
        </ul>
      </section>

      <section>
        <h2>14. Contact Information</h2>
        <p>For any questions regarding these Terms, please contact us at: <a href="mailto:slava@codehummus.com" target="_blank" rel="noreferrer">slava@codehummus.com</a></p>
      </section>

      <section>
        <h2>15. Severability</h2>
        <p>If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect.</p>
      </section>

      <section>
        <h2>16. Entire Agreement</h2>
        <p>These Terms constitute the entire agreement between you and Triple-Sided Flashcards regarding the use of the Application.</p>
      </section>
    </div>
  );
};

export default Terms;